@charset "utf-8";

// Update Bulma's global variables
$family-sans-serif: 'Montserrat', sans-serif;

$body-size: 15px;

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');

// Import a Font Awesome
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');

// Import only what you need from Bulma
@import '../../node_modules/bulma/bulma.sass';

// Import react-table css
@import '../../node_modules/react-table/react-table.css';

.hero-foo {
  background-image: url('../assets/banner.jpg');
  background-size: cover;
}

.course-list-bg {
  background: #1e468b url('../assets/bg.png');
  background-size: cover;
}

.bg-itabaiana {
  background: #1e468b url('../assets/unidade-senac-itabaiana.jpg');
  background-size: cover;
}

.hero-body .container {
  max-width: 700px;
}
.hero-body .title {
  color: hsl(192, 17%, 99%) !important;
}
.hero-body .subtitle {
  color: hsl(192, 17%, 99%) !important;
  padding-top: 2rem;
  line-height: 1.5;
}
.features {
  padding: 5rem 0;
}
.box.cta {
  border-radius: 0;
  border-left: none;
  border-right: none;
}
.card-image > .fa {
  font-size: 8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #209cee;
}
.card-content .content {
  font-size: 14px;
  margin: 1rem 1rem;
}
.card-content .content h4 {
  font-size: 16px;
  font-weight: 700;
}
.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  //margin-bottom: 2rem;
}
.intro {
  padding: 5rem 0;
  text-align: center;
}
.sandbox {
  padding: 5rem 0;
}
.tile.notification {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.is-shady {
  animation: flyintoright 0.4s backwards;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 0;
  border-radius: 4px;
  display: inline-block;
  margin: 5px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.is-shady:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.19);
}
/*adds font awesome stars*/
footer li:before {
  content: '\f1b2';
  font-family: 'FontAwesome';
  float: left;
  margin-left: -1.5em;
  color: #147efb;
}

.navbar-item img {
  max-height: 86px;
}

.footer {
  background-color: #004587;
}

.navbar-item {
  a {
    margin-right: 8px;
  }
}

.tabs ul {
  border-bottom-style: none;
}

.hero-body .title {
  color: #4a4a4a !important;
}

.bm--card-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bm--card-equal-height .card-footer {
  margin-top: auto;
}

.is-overlay {
  top: unset;
}

.level {
  a.button {
    margin: 2px 2px;
  }
}

.articles {
  margin: 5rem 0;
  margin-top: -200px;
}
.articles .content p {
  line-height: 1.9;
  margin: 15px 0;
}
.author-image {
  position: absolute;
  top: -30px;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  border: 3px solid #ccc;
  border-radius: 50%;
}
.media-center {
  display: block;
  margin-bottom: 1rem;
}

div.column.is-8:first-child {
  padding-top: 0;
  margin-top: 0;
}
.article-title {
  font-size: 2rem;
  font-weight: 600;
}
.article-subtitle {
  color: #909aa0;
  margin-bottom: 3rem;
}
.article-body {
  line-height: 1.4;
  margin: 0 6rem;
}

hr {
  background: transparent;
}

.control > span {
  font-weight: bold;
  font-size: 0.75em;
}
/* Single-direction drop shadow */

hr.separator {
  border: 0;
  margin: 0 5px;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    #ec890e,
    rgba(0, 0, 0, 0)
  );
}

.intro {
  padding: 2rem 1rem;
  text-align: center;
}

.navbar-burger {
  color: #147efb;
  font-size: 1.6em;
  height: 6.25rem;
}

.tags {
  justify-content: center;
}

.card-content.is-overlay {
  .tag {
    border-bottom: 3px solid #ec890e;
  }
}

.ReactTable .rt-thead.-filters input {
  border: 1px solid #ec890e !important;
  padding: 0 55px !important;
  font-size: 1.5em !important;
  border-radius: 3px !important;
  font-weight: bold !important;
  outline-width: 0;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #fff !important;
  padding: 20px 0;
  font-weight: bold !important;
  font-size: 1.2em !important;
}

@media (max-width: 415px) {
  .ReactTable .-pagination {
    display: contents !important;
  }

  .card-content {
    padding: 0.5rem !important;
  }

  .article-title {
    margin-top: 1rem;
  }

  .title.is-4 {
    margin-top: 1rem;
  }
}

.accordion a {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 1rem 3rem 1rem 1rem;
  color: #363636;
  font-size: 1.15rem;
  font-weight: 400;
  border-bottom: 1px solid #e5e5e5;
}

.accordion a:hover,
.accordion a:hover::after {
  cursor: pointer;
  color: #363636;
}

.accordion a:hover::after {
  //border: 1px solid #363636;
}

.accordion a.active {
  color: #363636;
  border-bottom: 1px solid #cccaca;
  font-weight: bold;
}

.accordion a::after {
  font-family: 'Ionicons';
  content: '\f3d0';
  position: absolute;
  float: right;
  right: 1rem;
  font-size: 1rem;
  color: #363636;
  padding: 5px;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  //border: 1px solid #363636;
  text-align: center;
}

.accordion a.active::after {
  font-family: 'Ionicons';
  content: '\f3d8';
  color: #363636;
  //border: 1px solid #363636;
}

.accordion .content {
  opacity: 0;
  padding: 0 1rem;
  max-height: 0;
  // border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
  clear: both;
  -webkit-transition: all 0.2s ease 0.15s;
  -o-transition: all 0.2s ease 0.15s;
  transition: all 0.2s ease 0.15s;
}

.accordion .content p {
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
}

.accordion .content.active {
  opacity: 1;
  padding: 1rem;
  max-height: 100%;
  -webkit-transition: all 0.35s ease 0.15s;
  -o-transition: all 0.35s ease 0.15s;
  transition: all 0.35s ease 0.15s;
}
